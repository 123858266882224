import React, { useState, useEffect } from 'react';
import AnimatedBackground from './AnimatedBackground';
import Navbar from '../components/Navbar';

const UpdatesPage = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch('/api/posts');
      if (response.ok) {
        const data = await response.json();
        setPosts(data);
      } else {
        throw new Error('Failed to fetch posts');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const formatTime = (date) => date.toLocaleTimeString('en-US', { hour12: false });

  const formatDate = (timestamp) => {
    if (timestamp) {
      const seconds = timestamp.seconds || timestamp._seconds;
      if (seconds) {
        return new Date(seconds * 1000).toLocaleDateString();
      }
    }
    return 'Unknown Date';
  };

  return (
    <div className="relative min-h-screen bg-gray-900 text-white font-sans">
      <AnimatedBackground />
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-900/95 to-blue-900/50 z-0"></div>
      <div className="relative z-10">
      <Navbar />
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-1 space-y-4 overflow-y-auto max-h-[calc(100vh-150px)] pr-2 custom-scrollbar">
            {posts.map((post, index) => (
              <div 
                key={post.id} 
                className={`bg-gray-800 rounded-lg p-4 cursor-pointer hover:bg-gray-700 transition duration-300 ${index === currentPost ? 'border-2 border-blue-400' : ''}`}
                onClick={() => setCurrentPost(index)}
              >
                <h3 className="text-lg font-bold mb-2">{post.title}</h3>
                {post.headerImage && (
                  <img 
                    src={post.headerImage} 
                    alt={post.title} 
                    className="w-full h-32 object-cover rounded-lg mb-2" 
                  />
                )}
                <p className="text-sm text-gray-300 line-clamp-3">
                  {post.content.replace(/<[^>]*>/g, '')}
                </p>
                <p className="text-xs text-gray-400 mt-2">{formatDate(post.date)}</p>
              </div>
            ))}
          </div>
          {posts.length > 0 && (
            <div className="col-span-3 bg-gray-800 rounded-lg shadow-lg overflow-hidden">
              <div className="relative h-64">
                {posts[currentPost].headerImage && (
                  <img 
                    src={posts[currentPost].headerImage} 
                    alt={posts[currentPost].title} 
                    className="w-full h-full object-cover" 
                  />
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
                <h2 className="absolute bottom-4 left-6 text-3xl font-bold text-white shadow-text">
                  {posts[currentPost].title}
                </h2>
              </div>
              <div className="p-6">
                <div className="flex items-center mb-4 text-sm text-gray-400">
                  <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                  </svg>
                  {formatDate(posts[currentPost].date)}
                </div>
                <div 
                  className="prose prose-invert max-w-none"
                  dangerouslySetInnerHTML={{ __html: posts[currentPost].content }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx global>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: rgba(31, 41, 55, 0.5);
          border-radius: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(59, 130, 246, 0.5);
          border-radius: 4px;
          transition: background 0.3s ease;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: rgba(59, 130, 246, 0.7);
        }
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: rgba(59, 130, 246, 0.5) rgba(31, 41, 55, 0.5);
        }
        .custom-scrollbar:hover {
          scrollbar-color: rgba(59, 130, 246, 0.7) rgba(31, 41, 55, 0.5);
        }
        .shadow-text {
          text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
        }
        .markdown-body {
          color: #e2e8f0;
          line-height: 1.7;
          font-size: 16px;
        }
        .markdown-body h1,
        .markdown-body h2,
        .markdown-body h3,
        .markdown-body h4,
        .markdown-body h5,
        .markdown-body h6 {
          margin-top: 28px;
          margin-bottom: 20px;
          font-weight: 600;
          line-height: 1.3;
          color: #f8fafc;
        }
        .markdown-body h1 { font-size: 2.25em; border-bottom: 1px solid #4b5563; padding-bottom: 0.3em; }
        .markdown-body h2 { font-size: 1.75em; border-bottom: 1px solid #4b5563; padding-bottom: 0.3em; }
        .markdown-body h3 { font-size: 1.5em; }
        .markdown-body h4 { font-size: 1.25em; }
        .markdown-body h5 { font-size: 1em; }
        .markdown-body h6 { font-size: 0.875em; color: #94a3b8; }
        .markdown-body p {
          margin-top: 0;
          margin-bottom: 16px;
        }
        .markdown-body a {
          color: #60a5fa;
          text-decoration: none;
          transition: color 0.2s ease;
        }
        .markdown-body a:hover {
          color: #93c5fd;
          text-decoration: underline;
        }
        .markdown-body ul,
        .markdown-body ol {
          margin-top: 0;
          margin-bottom: 16px;
          padding-left: 2em;
        }
        .markdown-body ul { list-style-type: disc; }
        .markdown-body ol { list-style-type: decimal; }
        .markdown-body li {
          margin-bottom: 0.25em;
        }
        .markdown-body blockquote {
          margin: 0 0 16px;
          padding: 0.5em 1em;
          color: #94a3b8;
          border-left: 0.25em solid #4b5563;
          background-color: rgba(75, 85, 99, 0.2);
          border-radius: 0 0.3em 0.3em 0;
        }
        .markdown-body code {
          padding: 0.2em 0.4em;
          margin: 0;
          font-size: 85%;
          background-color: rgba(75, 85, 99, 0.4);
          border-radius: 3px;
          font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
        }
        .markdown-body pre {
          padding: 16px;
          overflow: auto;
          font-size: 85%;
          line-height: 1.45;
          background-color: #1f2937;
          border-radius: 6px;
          margin-bottom: 16px;
        }
        .markdown-body pre code {
          display: inline;
          max-width: auto;
          padding: 0;
          margin: 0;
          overflow: visible;
          line-height: inherit;
          word-wrap: normal;
          background-color: transparent;
          border: 0;
        }
        .markdown-body table {
          border-spacing: 0;
          border-collapse: separate;
          margin-bottom: 16px;
          width: 100%;
          border: 1px solid #4b5563;
          border-radius: 6px;
          overflow: hidden;
        }
        .markdown-body td,
        .markdown-body th {
          padding: 8px 13px;
          border: 1px solid #4b5563;
        }
        .markdown-body th {
          font-weight: 600;
          background-color: #374151;
        }
        .markdown-body tr {
          background-color: #1f2937;
        }
        .markdown-body tr:nth-child(2n) {
          background-color: #252f3f;
        }
        .markdown-body a {
          position: relative;
        }
        .markdown-body a::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: #60a5fa;
          transform: scaleX(0);
          transform-origin: bottom right;
          transition: transform 0.3s ease;
        }
        .markdown-body a:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      `}</style>
    </div>
  );
};

export default UpdatesPage;
