// src/pages/LandingPage.js

import React, { useState } from 'react';
import AnimatedBackground from './AnimatedBackground';
import Navbar from '../components/Navbar';
import { Typewriter } from 'react-simple-typewriter';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [joined, setJoined] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/leads', {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setJoined(true);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      alert('Failed to submit email. Please try again.');
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden bg-gray-900 text-white font-sans">
      <AnimatedBackground />
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-900/95 to-blue-900/50 z-0"></div>
      <div className="relative z-10">
        <Navbar />
        <main className="container mx-auto px-6 py-12 text-center">
          <h1 className="text-5xl md:text-7xl font-bold mb-6">
            A.I. <span className="text-blue-400">Creative</span>
          </h1>
          <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto text-gray-300">
            Business | Entertainement | Education
          </p>
          {joined ? (
            <div className="text-lg text-gray-300">
              <Typewriter
                words={['You have joined the waitlist']}
                loop={1}
                cursor
                cursorStyle='_'
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="flex flex-col sm:flex-row gap-4">
                <input 
                  type="email" 
                  placeholder="Enter your email"
                  className="flex-grow px-4 py-3 rounded-md bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-blue-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button 
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-md transition duration-300"
                >
                  Join Waitlist
                </button>
              </div>
            </form>
          )}
        </main>

        <section className="container mx-auto px-6 py-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">Explore the Boundaries of Intelligence</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-lg">
              <h3 className="text-xl font-bold mb-4">Consulting</h3>
              <p className="text-gray-300">
                Leveraging AI doesn't have to be complicated. Access custom solutions from Sensara Suite or let our team of experts build a solution for you.
              </p>
            </div>
            <div className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-lg">
              <h3 className="text-xl font-bold mb-4">Sensara Suite</h3>
              <p className="text-gray-300">
                Explore the many tools and solutions available in Sensara Suite to begin forming your own AI stack. The time to start is now.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LandingPage;
