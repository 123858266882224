// src/pages/AdminPage.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Navbar from '../components/Navbar';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const AdminPage = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [headerImage, setHeaderImage] = useState(null);
    const [headerImagePreview, setHeaderImagePreview] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const quillRef = useRef();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setAuthenticated(!!user);
        });

        return () => unsubscribe();
    }, []);

    const handleTitleChange = useCallback((e) => {
        setTitle(e.target.value);
    }, []);

    const handleContentChange = useCallback((value) => {
        setContent(value);
    }, []);

    const handleHeaderImageChange = useCallback((e) => {
        const file = e.target.files[0];
        if (file) {
            setHeaderImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setHeaderImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleImageUpload = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = async () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append('image', file);
    
            try {
                const response = await fetch('/api/posts/upload', {
                    method: 'POST',
                    body: formData,
                });
    
                if (response.ok) {
                    const data = await response.json();
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection(true);
                    quill.insertEmbed(range.index, 'image', data.url);
                } else {
                    throw new Error('Image upload failed');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                alert('Failed to upload image. Please try again.');
            }
        };
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        
        if (headerImage) {
            formData.append('headerImage', headerImage);
            console.log('Header image appended to formData:', headerImage);
        }
    
        try {
            const response = await fetch('/api/posts', {
                method: 'POST',
                body: formData,
            });
                
            if (response.ok) {
                const newPost = await response.json();
                console.log('New post created:', newPost);
                setTitle('');
                setContent('');
                setHeaderImage(null);
                setHeaderImagePreview('');
                alert('Post created successfully!');
            } else {
                throw new Error('Failed to create post');
            }
        } catch (error) {
            console.error('Error creating post:', error);
            alert('Failed to create post. Please try again.');  
        }
    }, [title, content, headerImage]);

    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link', 'image'],
                ['clean']
            ],
            handlers: {
                image: handleImageUpload
            }
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error('Error signing in:', error);
            alert('Failed to sign in. Please check your credentials.');
        }
    };

    if (!authenticated) {
        return (
            <div className="min-h-screen bg-gray-900 text-white p-6 flex items-center justify-center">
                <div className="bg-gray-800 p-6 rounded-lg">
                    <h1 className="text-2xl font-bold mb-4">Admin Login</h1>
                    <form onSubmit={handleLogin} className="space-y-4">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full bg-gray-700 rounded p-2 text-white"
                            placeholder="Email"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full bg-gray-700 rounded p-2 text-white"
                            placeholder="Password"
                            required
                        />
                        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-900 text-white p-6">
            <Navbar />
            <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg p-6">
                <h1 className="text-3xl font-bold mb-6">Create New Post</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="title" className="block mb-2">Title</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={handleTitleChange}
                            className="w-full bg-gray-700 rounded p-2 text-white"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="headerImage" className="block mb-2">Header Image</label>
                        <input
                            type="file"
                            id="headerImage"
                            onChange={handleHeaderImageChange}
                            className="w-full bg-gray-700 rounded p-2 text-white"
                            accept="image/*"
                        />
                        {headerImagePreview && (
                            <img src={headerImagePreview} alt="Header preview" className="mt-2 max-w-full h-auto rounded" />
                        )}
                    </div>
                    <div>
                        <label htmlFor="content" className="block mb-2">Content</label>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            value={content}
                            onChange={handleContentChange}
                            modules={modules}
                            className="bg-gray-700 rounded text-white"
                        />
                    </div>
                    <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4">
                        Publish Post
                    </button>
                </form>
            </div>
            <style jsx global>{`
                .quill {
                    background-color: #374151;
                    border-radius: 0.375rem;
                    margin-bottom: 1rem;
                }
                .ql-toolbar {
                    background-color: #4B5563;
                    border-top-left-radius: 0.375rem;
                    border-top-right-radius: 0.375rem;
                    border-color: #6B7280;
                }
                .ql-container {
                    background-color: #374151;
                    border-bottom-left-radius: 0.375rem;
                    border-bottom-right-radius: 0.375rem;
                    border-color: #6B7280;
                    color: white;
                    font-size: 16px;
                }
                .ql-editor {
                    min-height: 200px;
                }
                .ql-editor.ql-blank::before {
                    color: #9CA3AF;
                }
                .ql-stroke {
                    stroke: #E5E7EB;
                }
                .ql-fill {
                    fill: #E5E7EB;
                }
                .ql-picker {
                    color: #E5E7EB;
                }
                .ql-picker-options {
                    background-color: #4B5563;
                    border-color: #6B7280;
                }
                .ql-picker-item {
                    color: #E5E7EB;
                }
                .ql-picker-item:hover {
                    color: #60A5FA;
                }
                .ql-active {
                    color: #60A5FA;
                }
            `}</style>
        </div>
    );
};

export default AdminPage;
